.shop-page-background {
    opacity: 0.6;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    position: absolute;
    background-color:rgba(0, 0, 0, 0.2);
    
    .img {
      /* tile the image */
      background-size: 100% 100%;
      background-repeat: repeat;
      background-position: center;
      width: 100%;

    }
}

.BelenShopPage {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-bottom: 10px;
    background-color: #000;

    a.anchor {
      display: block;
      position: relative;
      top: -150px;
      visibility: hidden;
    }
  
    .belen-shop-title {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 20px;
      color: #fff;
    }
  }
  

/* 480 and smaller - Mobile */
@media screen and (max-width: 480px) {
    .best-sellers-title {
      font-size: 24px!important;
    }
}


#product-container {
    display: block;
    position: relative;
    z-index: 9; 
}