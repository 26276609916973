.contact-modal {
    z-index: 1300;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-modal-content {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    min-width: 300px;
    padding: 20px;
    width: 30vw;
    text-align: center;
}

.contact-modal-content img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
}

.contact-modal-content h2 {
    margin-top: 20px;
}

.contact-modal-content p {
    margin-bottom: 10px;
}

/* Phone number styles */
.phone-link, .phone-text {
    font-size: 20px;
    font-weight: bold;
    color: #146C43;
}

/* Desktop: Show text, hide link */
.phone-link {
    display: none;
    text-decoration: none;
    pointer-events: none; /* Ensure no clicking on desktop */
}

.phone-text {
    display: inline;
    cursor: default; /* Regular cursor for non-clickable text */
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .contact-modal-content {
        width: 90vw;
    }

    /* Mobile: Show link, hide text */
    .phone-link {
        display: inline;
        pointer-events: auto; /* Enable clicking on mobile */
        text-decoration: none;
    }

    .phone-text {
        display: none;
    }
}
