.product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20vw;
    background-color: #fff;
    margin: 10px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 10px;
    .product-details {
      width: 100%;
      padding-right: 10px;
    }
    .product-name {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 0;
      text-align: left;
    }
    .product-image {
      width: 100%;
      height: 60%;
      min-height: 13vw;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 10px;
  
      display: block;
      position: relative;
    }
  
    .badge {
      background-color: #146C43;
      color: #fff;
      padding: 5px 10px;
      font-size: 0.7rem;
      font-weight: 700;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin: 3px;
    }
  
    .price-badge {
      background-color: #fff;
      color: #000;
      position: absolute;
      top: 0;
      left: 0;
    }
  
    .hot-badge {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #DC3545;
    }
    .product-img-bottom-badges {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px 0px;
    }
  
    .indica-badge {
      background-color: #563D7C;
    }
  
    .sativa-badge {
      background-color: #FFAA06;
    }
  }

  .product-description {
    font-size: 0.6rem;
    font-weight: 400;
    margin-top: 0;
    text-align: left;
    color: #000;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }



  /* 1024 and smaller */
  @media screen and (max-width: 1024px) {
    .product-card {
      width: 20vw;
      height: 35vw;
    }
    .product-image {
      height: 45%!important;
    }
    .product-description {
      font-size: 0.8rem!important;
    }
  }

  /* 768 and smaller - Tablet */
  @media screen and (max-width: 768px) {
    .product-card {
      width: 35vw;
      height: 50vw;
    }
    .product-card .product-name {
      font-size: 1rem!important;
    }
    .product-description {
      font-size: 0.8rem!important;
    }
}


/* 480 and smaller - Mobile */
@media screen and (max-width: 480px) {
    .product-card {
      width: 80vw;
      height: auto;
    }
    .product-card .product-name {
      font-size: 1.2rem;
    }

    .product-image {
        min-height: 250px!important;
      }
      .product-name {
        font-size: 1rem!important;
      }
    
}