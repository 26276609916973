.image-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: absolute;

    .current-image, img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        padding-top: 75px;
        opacity: 0.8;
    }

    .prev-button button, .next-button button {
        background-color: rgba(0,0,0, 0);
        border: none;
        width: 50px!important;
        min-width: 50px;
    }

    .prev-button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 24px;
        cursor: pointer;
        width: 60px;
        z-index: 999;
    }
    .next-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 24px;
        cursor: pointer;
        width: 60px;
        z-index: 999;
    }
}

.transition-right {
    animation: fadeinRight 0.5s ease-in-out;
  }
  
.transition-fade {
    animation: fade 0.2s ease-in-out;
}
  
  @keyframes fadeinRight {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fade{
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  


    /* 768 and smaller - Tablet */
    @media screen and (max-width: 768px) {
        .image-slider .current-image, .current-image img {
            padding-top: 0;
        }
      }