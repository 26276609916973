.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="date"]
{
    display:block;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    min-height: 1.2em;
}

button, .link-button {
  background-color: #146C43;
  text-decoration: none;
  min-width: 200px;
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  border: none;
  font-weight: 700;
  cursor: pointer;
  border-radius: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 10;
  position: relative;
}

  /* 768 and smaller - Tablet */
  @media screen and (max-width: 768px) {
    .container {
      width: 90%;
    }
}


.animated-element {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
