.hero-section {
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85vh;
    z-index: 99;
    position: relative;
  }
  
  .hero-background {
    opacity: 0.8;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 85vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .hero-content {
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .hero-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
    span {
      color: #146C43;
    }
  }

  .hero-button {
    margin-top: 10px;
    font-size: 16px;
    padding: 8px 16px;
    min-width: 133px;
  }

  /* 768 and smaller - Tablet */
  @media screen and (max-width: 768px) {
    .hero-title {
      font-size: 28px;
    }
    .hero-background, .hero-section {
      height: 50vh;
    }
    .hero-background {
      opacity: 0.4;
    }

    .hero-button {
      font-size: 14px;
      padding: 10px 20px;
      min-width: 150px;
    }
  }

  /* 480 and smaller - Mobile */
  @media screen and (max-width: 480px) {
    .hero-title {
      font-size: 24px;
    }
    .hero-background, .hero-section {
      height: 50vh;
    }
    .hero-background {
      opacity: 0.4;
    }

    .hero-button {
      font-size: 16px;
      padding: 12px 24px;
      min-width: 200px;
    }
  }
