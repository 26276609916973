.about-section {
    background-color: #146C43;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #fff;
    clip-path: polygon(100% 0%, 0% 0% , 0.00% 98.33%, 2.00% 98.32%, 4.00% 98.28%, 6.00% 98.21%, 8.00% 98.11%, 10.00% 97.98%, 12.00% 97.83%, 14.00% 97.65%, 16.00% 97.45%, 18.00% 97.23%, 20.00% 96.98%, 22.00% 96.71%, 24.00% 96.42%, 26.00% 96.12%, 28.00% 95.80%, 30.00% 95.46%, 32.00% 95.12%, 34.00% 94.76%, 36.00% 94.39%, 38.00% 94.02%, 40.00% 93.65%, 42.00% 93.27%, 44.00% 92.89%, 46.00% 92.52%, 48.00% 92.15%, 50.00% 91.79%, 52.00% 91.43%, 54.00% 91.09%, 56.00% 90.76%, 58.00% 90.45%, 60.00% 90.15%, 62.00% 89.87%, 64.00% 89.60%, 66.00% 89.36%, 68.00% 89.15%, 70.00% 88.95%, 72.00% 88.78%, 74.00% 88.64%, 76.00% 88.52%, 78.00% 88.43%, 80.00% 88.37%, 82.00% 88.34%, 84.00% 88.33%, 86.00% 88.36%, 88.00% 88.41%, 90.00% 88.49%, 92.00% 88.60%, 94.00% 88.73%, 96.00% 88.89%, 98.00% 89.08%, 100.00% 89.29%);
   }

.about-section-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section-image {
    width: 70%;
    max-width: 600px;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        max-height: 400px;
        object-fit: cover;
        border-radius: 20px;
    }
}

.about-section-content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    h2 {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: left;

    }

    a {
        background-color: #fff;
        color: #146C43;
    }
}

/* 768 and smaller - Tablet */
@media screen and (max-width: 768px) {
    .about-section {
        padding-top: 20px;
        padding-bottom: 60px;
    }

    .about-section-container {
        flex-direction: column;
    }

    .about-section-image {
        width: 90%;
        height: 100%;
        img {
            max-height: 300px;
        }
    }
    .about-section-content {
        width:90%;
        height: 100%;
        padding: 20px;
        h2 {
            font-size: 24px;
            margin-bottom: 0;
            margin-top: 0;
        }
        p {
            font-size: 16px;
            text-align: center;
        }
    }
}

/* 480 and smaller - Mobile */
@media screen and (max-width: 480px) {
    .about-section {
        padding-top: 20px;
        padding-bottom: 70px;
    }
}