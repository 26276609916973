.hero-section-about {
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85vh;
    z-index: 99;
    touch-action: none;
  }
  
  .hero-background-about {
    opacity: 0.8;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 85vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  
  .hero-title-about {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
    z-index: 999;
    span {
      color: #146C43;
    }
  }

  .link-button {
    z-index: 999;
  }

    /* 768 and smaller - Tablet */
    @media screen and (max-width: 768px) {
      .hero-title-about {
        font-size: 28px;
      }
      .hero-background-about {
        opacity: 0.5;
      }
      .hero-background-about, .hero-section-about {
        height: 45vh;
        margin-top: 90px;
      }

      .hero-section-about {
        .anchor {
          position: absolute;
          top: 0;
        }

        a {
          font-size: 14px!important;
        }
      }
    }

    /* 480 and smaller - Mobile */
@media screen and (max-width: 480px) {
  .hero-title-about {
    font-size: 20px;
  }
  .hero-background-about, .hero-section-about {
    height: 50vh;
    margin-top: 90px;
  }
  
}