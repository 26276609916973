
.experience-more {
    margin-bottom: 8%;
    background-color: #fff;
}
  
.experience-more-title {
font-size: 36px;
font-weight: 700;
width: 100%;
text-align: left;
    span {
        color: #146C43;
    }
}
  
.experience-more-item-title {
font-size: 24px;
font-weight: 700;
margin-bottom: 20px;
text-align: left;
}

.experience-more-item-text, .experience-more-bottom-text {
font-size: 18px;
font-weight: 400;
margin-bottom: 20px;
text-align: left;
width: 100%;
}