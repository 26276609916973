.reviews-pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 80%;
    z-index: 99;
}

.reviews-section {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #127848;
    color: #fff;
    min-height: 300px;
    z-index: 99;

    .reviews-title-container {
        background-color: #146C43;
        width: 100%;
        z-index: 99;

    }
}

.review-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reviews-pagination-button {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px;
}

.review p {
    text-align: left;
}

@media screen and (max-width: 768px) {
        .reviews-pagination-container {
            width: 100%;
        }
        .reviews-pagination-button {
            font-size: 20px;
        }
        .reviews-title {
            font-size: 16px;
        }
}