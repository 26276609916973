.AboutPage {
    width: 100%;
    background-color: #fff;
}

.about-container {
    padding: 2rem;
    background-color: #f9f9f9;
    color: #333;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
}

.about-welcome-section {
    text-align: center;
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: #146C43;
    color: #fff;
    border-radius: 10px;
}

.about-welcome-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about-welcome-section p {
    font-size: 1.2rem;
}

.about-section {
    margin-bottom: 2rem;
    text-align: center;
}

.about-section h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #146C43;
}

.about-section p {
    margin-bottom: 0.75rem;
}

.about-icon {
    font-size: 3rem;
    color: #146C43;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .about-container {
        padding: 1rem;
    }

    .about-welcome-section {
        padding: 1rem;
    }

    .about-welcome-section h2 {
        font-size: 1.5rem;
    }

    .about-welcome-section p {
        font-size: 1rem;
    }

    .about-section h3 {
        font-size: 1.3rem;
    }

    .about-section p {
        font-size: 0.9rem;
    }

    .about-icon {
        font-size: 2.5rem;
    }
}
