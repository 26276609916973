.age-verification-modal {
    z-index: 1300;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.age-verification-modal-content {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    min-width: 300px;
    padding: 20px;
    width: 30vw;
    text-align: center;
}

.age-verification-modal-content img {
    width: 100px;
    height: 100px;
}

.age-verification-modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.age-verification-modal-form input {
    width: 25%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.caution-sign {
  display: inline-block;
  animation: flash 1s linear infinite;
  margin: 0 5px;
}
