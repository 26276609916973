.contact-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    width: 100%;
    min-height: 150px;
    z-index: 99;
    position: relative;
}

.contact-bar-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.contact-bar-item {
    font-size: 20px;
    font-weight: 700;
    min-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-bar-item svg {
    color: #146C43;
    font-size: 24px;
}

.contact-bar-item span,
.contact-bar-item .address-link,
.contact-bar-item .phone-link,
.contact-bar-item .phone-text {
    margin-left: 10px;
    color: #fff;
    text-decoration: none;
}

.contact-bar-item .address-link:hover,
.contact-bar-item .phone-link:hover {
    text-decoration: underline;
}

/* Phone number styles */
.contact-bar-item .phone-link,
.contact-bar-item .phone-text {
    margin-left: 10px;
    color: #fff;
}

/* Desktop: Show text, hide link */
.contact-bar-item .phone-link {
    display: none;
    text-decoration: none;
    pointer-events: none; /* Ensure no clicking on desktop */
}

.contact-bar-item .phone-text {
    display: inline;
    cursor: default; /* Regular cursor for non-clickable text */
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .contact-bar {
        min-height: 100px;
    }
    
    .contact-bar-item {
        font-size: 16px;
    }
    
    .contact-bar-item svg {
        font-size: 20px;
    }

    /* Mobile: Show link, hide text */
    .contact-bar-item .phone-link {
        display: inline;
        pointer-events: auto; /* Enable clicking on mobile */
        text-decoration: none;
    }

    .contact-bar-item .phone-text {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .contact-bar {
        min-height: auto;
        padding: 20px 0;
    }
    
    .contact-bar-content {
        flex-direction: column;
        align-items: center;
    }
    
    .contact-bar-item {
        font-size: 14px;
        margin-bottom: 15px;
        width: 100%;
        justify-content: center;
        padding-left: 0;
        text-align: center;
    }
    
    .contact-bar-item svg {
        font-size: 18px;
    }
}
