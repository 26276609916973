.footer {
    background-color: #000000;
    padding: 2rem 1rem;
    color: white;
    display: flex;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    flex: 0 0 150px;
    margin-right: 2rem;
    margin-bottom: 1rem;
    align-self: center;
}

.logo img {
    max-width: 100%;
    height: auto;
}

.links-and-map-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-links {
    flex: 1 1 300px;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.footer-links h2 {
    text-align: center;
    margin-bottom: 1rem;
    color: white;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 0.5rem;
}

.footer-button {
    width: 100%;
    justify-content: flex-start !important;
    text-transform: none !important;
    font-size: 0.9rem !important;
    padding: 0.5rem 1rem !important;
    background-color: #146C43 !important; /* Changed back to green */
    color: white !important;
}

.footer-button:hover {
    background-color: #0F5132 !important; /* Darker green for hover state */
}

.footer-button .MuiButton-startIcon {
    margin-right: 0.5rem;
}

.facebook-button {
    background-color: #1877F2 !important;
}

.facebook-button:hover {
    background-color: #166FE5 !important;
}

.phone-button {
    background-color: #90EE90 !important;
    font-weight: bold !important;
    letter-spacing: 0.5px !important;
    color: #333 !important;
}

.phone-button:hover {
    background-color: #7CCD7C !important;
}

.footer-map {
    flex: 1 1 300px;
    display: flex;
    justify-content: center;
    min-height: 200px;
    background: #f0f0f0;
}

.footer-map iframe {
    width: 100% !important;
    max-width: 300px;
    height: 200px !important;
    border: 0;
    display: block;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .logo {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .links-and-map-container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .footer-links, .footer-map {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .footer-map iframe {
        height: 250px;
    }
}

@media (max-width: 320px) {
    .footer {
        padding: 1rem 0.5rem;
    }

    .footer-button {
        font-size: 0.8rem !important;
        padding: 0.4rem 0.8rem !important;
    }
}

/* Desktop phone button styles */
@media screen and (min-width: 769px) {
    .footer-button.phone-button {
        pointer-events: none !important;
        cursor: default !important;
    }
    
    .footer-button.phone-button:hover {
        background-color: #146C43 !important; /* Keep default color, prevent hover effect */
    }
}

.footer-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    background-color: #000;
    color: white;
}

.map-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    background: #f0f0f0;
    color: #333;
    font-size: 14px;
}

.loading-indicator {
    margin-top: 10px;
    width: 30px;
    height: 30px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #146C43;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.map-error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    background: #fee;
    color: #c00;
    padding: 1rem;
    text-align: center;
    font-size: 14px;
}
