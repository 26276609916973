.header {
    background-color: #000000;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    max-height: 100px;
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9990;
    transition: transform 0.3s ease-in-out;

    .navbar-mobile-menu {
      display: none;
    }

    .logo {
      cursor: pointer;
    }
  
    .logo img {
      width: 100px;
      height: 100px;
    }
  
    .search-bar {
      display: flex;
      align-items: center;
      width: 40%;
    }
  
    .navbar  {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      overflow: visible;

    
      .nav-item a {
        color: #fff;
        text-decoration: none;
        font-weight: 700;
      }
      .nav-item a:hover, .nav-item a:focus, .nav-item a:active, .active a {
        color: #146C43;
      }
    }
  }

  /* remove bullet points from list items */
  .navbar-nav {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .nav-item {
    padding-right: 20px;
    padding-left: 20px;
    text-decoration: none;
  }

    /* 768 and smaller - Tablets */
  @media screen and (max-width: 768px) {
    .header {
      overflow: visible;
      max-height: 75px;
    }

    .logo img {
      width: 75px !important;
      height: 75px !important;
    }
    .navbar-nav {
      display: none!important;
    }

    .navbar-mobile {
      font-size: 1.5rem;
    }

    .navbar-mobile-menu {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex!important;
      padding-left: 25%;
    }

    .navbar-nav-mobile {
      width: 100%;
      top: 75px;
      left: 0;
      z-index: 9990;
      position: fixed;
      display: flex!important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      padding: 0;
      margin: 0;
      background-color: #000;
      padding-top: 15px;
      max-height: calc(100vh - 75px);
      overflow-y: auto;
      .nav-item {
        padding: 10px;
        text-decoration: none;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        width: 100%!important;
      }
    }

    .header.modal-open {
        transform: translateY(-100%);
    }
  }

  .navbar.shrink {
    max-height: 75px;
    transition: max-height 0.3s ease-in-out;

  }
  .header.shrink {
    max-height: 75px;
    transition: max-height 0.3s ease-in-out;
  }
  .logo.shrink {

    img {
      width: 60px!important;
      height: 60px!important;
      transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    }
  }

    /* Shrink mobile and tablet - hide navbar if shrink */
    @media screen and (max-width: 768px) {
      .header.shrink {
        display: none!important;
      }
      
    }
